<template>
    <ODataGrid :data-object="dsActivities" hideGridMenu=true filter-row ref="grid" :headerTitle='$t("Activities")'>
        
        <template #cardheader>
                    
            <div class="btn-group">            
                <button class="btn btn-outline-primary btn-sm dropdown-toggle me-2" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-bars mr-1"></i> {{$t("Filter")}}
                </button>

                <ul class="dropdown-menu">
                    <button class="dropdown-item" @click="applyFilter('MyActivity=1')">
                        <span class="text-content" style="padding-left:0.15rem;">{{ $t("Activities where I have role/responsibility") }}</span> 
                    </button>        
                    <button class="dropdown-item" @click="applyFilter('IShallSign=1')">
                        <span class="text-content" style="padding-left:0.15rem;">{{ $t("Activities I shall sign") }}</span> 
                    </button>        
                    <button class="dropdown-item" @click="applyFilter('HaveDeletedObjects=1')">
                        <span class="text-content" style="padding-left:0.15rem;">{{ $t("Activities with deleted objects") }}</span> 
                    </button>        
                    <button class="dropdown-item" @click="applyFilter('')">
                        <span class="text-content" style="padding-left:0.15rem;">{{ $t("Show all activities/remove filter") }}</span> 
                    </button>        
                </ul>
            </div>
            
            <div class="d-inline">
                <input type="text" class="form-control form-control-sm ml-2 d-inline" :placeholder='$t("Search Content")' @keyup="searchContent" style="width:300px;vertical-align: bottom;">
            </div>

            <div class=" form-check mx-3 form-switch">        
                <input class="form-check-input" id="include-deleted" type="checkbox" v-model="includeDeletedToggle">
                <label class="" for="include-deleted">{{$t("Include Deleted/Cancelled Activities")}}</label>
            </div>
        
            <div class=" form-check mx-3 form-switch">        
                <input class="form-check-input" id="include-closed" type="checkbox" v-model="includeClosedToggle">
                <label class="" for="include-closed">{{$t("Include Closed Activities")}}</label>
            </div>
        </template>
        
        <OColumn :classFn="row => row.Deleted || row.Closed ? 'text-decoration-line-through' : ''" field="UniqueID" width="110" sortable :headerName='$t("UniqueID")' :headerTitle='$t("UID")' cellrenderer="OLink" cellrendererparams="/activity-checklist?ID={{ID}}" />
        <OColumn :classFn="row => row.Deleted || row.Closed ? 'text-decoration-line-through' : ''" v-slot="{row}" width="75" :headerName='$t("Setup")' field="ID" :headerTitle='$t("Setup")' cellrenderer="OLink" cellrendererparams="">
            <a :href="`/activity?ID=${row.ID}`">{{$t("Setup")}}</a>
        </OColumn>
        
        <OColumn field="OrgUnit" editable width="225" sortable menu v-slot:editor="{modelValue: row}" bulkUpdate>
            <OOrgunitsLookup :bind="sel=>{row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name;}">
                    <template #orgunit>
                        <input type="text" id="orgUnitInput" v-model="row.OrgUnit">
                    </template>
            </OOrgunitsLookup>
        </OColumn> 

        <OColumn field="ActivityType" editable bulkUpdate width="200" sortable :headerName='$t("Activity Type")' v-slot:editor="{modelValue: row}" :headerTitle='$t("Activity Type")'>
            <ActivityTypesLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ActivityType_ID = sel.ID; row.ActivityType = sel.Name; row.ActivityGroup_ID = sel.ActivityGroup_ID}" textInput :textInputValue="row.ActivityType">
            </ActivityTypesLookup>
        </OColumn>
        <OColumn field="ActivityGroup" width="250" sortable :headerName='$t("Activity Group")' :headerTitle='$t("Activity Group")' />
        <OColumn field="Name" width="350" editable bulkUpdate sortable :headerName='$t("Name")' :headerTitle='$t("Giving the activity a good name makes it easier for participants to know what to expect of the activity and to find it again when its completed")' />
        <OColumn field="Description" width="250" editable bulkUpdate sortable :headerName='$t("Description")' :headerTitle='$t("Description")' />
        <OColumn field="Responsibles" width="250"  :headerName='$t("Responsibles")' :headerTitle='$t("Responsibles")'  />
        <OColumn field="HandoverType" width="250" editable bulkUpdate :headerName='$t("Handover Type")' :headerTitle='$t("Handover Type")' v-slot:editor="{modelValue: row}" hide >
            <HandoverTypesLookup 
                :IdPath="row.OrgUnitIdPath" 
                :bind="sel=>{
                        row.HandoverType_ID = sel.ID;
                        row.HandoverType = sel.Name;
                        row.Checklist=sel.Checklist;
                        row.Checklist_ID=sel.Checklist_ID;
                        row.Revision=sel.Revision;
                        row.CompletionPhase_ID=sel.CompletionPhase_ID;
                        row.CompletionPhase=sel.CompletionPhase; 
                        row.ActivityType_ID=sel.ActivityType_ID; 
                        row.ActivityType = sel.ActivityType}" textInput :textInputValue="row.HandoverType">
            </HandoverTypesLookup>
        </OColumn>
        <OColumn field="Discipline" width="250" editable bulkUpdate :headerName='$t("Discipline")' :headerTitle='$t("Discipline")' v-slot:editor="{modelValue: row}" >
            <DisciplinesLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.Discipline_ID = sel.ID; row.Discipline = sel.NameAndTitle;}" textInput :textInputValue="row.Discipline">
            </DisciplinesLookup>
        </OColumn>
        <OColumn field="Sequence" width="100" editable bulkUpdate sortable :headerName='$t("Sequence")' :headerTitle='$t("Sequence")' hide />
        <OColumn field="Series" width="100" sortable :headerName='$t("Series")' :headerTitle='$t("Series")' hide />
        <OColumn field="CompletionPhase" width="250" editable bulkUpdate :headerName='$t("Completion Phase")' :headerTitle='$t("Completion Phase")' v-slot:editor="{modelValue: row}">
            <CompletionPhasesLookup 
                :OrgUnitId="row.OrgUnit_ID" :bind="sel=>{row.CompletionPhase_ID = sel.ID; row.CompletionPhase = sel.NameAndDescription;}" textInput :textInputValue="row.CompletionPhase">
            </CompletionPhasesLookup>
        </OColumn>
        <OColumn field="ProjectPhase" width="250" editable bulkUpdate :headerName='$t("Project Phase")' :headerTitle='$t("Project Phase")' v-slot:editor="{modelValue: row}" hide>
            <ProjectPhasesLookup 
                :OrgUnitId="row.OrgUnit_ID" :bind="sel=>{row.ProjectPhase_ID = sel.ID; row.ProjectPhase = sel.Name;}" textInput :textInputValue="row.ProjectPhase">
            </ProjectPhasesLookup>
        </OColumn>
        <OColumn field="SubProject" width="250" editable bulkUpdate :headerName='$t("Sub Project")' :headerTitle='$t("Sub Project")' v-slot:editor="{modelValue: row}" hide >
            <SubProjectsLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.SubProject_ID = sel.ID; row.SubProject = sel.Name;}" textInput :textInputValue="row.SubProject">
            </SubProjectsLookup>
        </OColumn>
        <OColumn field="ClassificationCode" width="250" editable bulkUpdate :headerName='$t("Classification Code")' :headerTitle='$t("Classification Code")' v-slot:editor="{modelValue: row}" hide >
            <ClassificationCodesLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ClassificationCode_ID = sel.ID; row.ClassificationCode = sel.Name;}" textInput :textInputValue="row.ClassificationCode">
            </ClassificationCodesLookup>
        </OColumn>
        <OColumn field="NotApplicable" editable bulkUpdate width="100" sortable :headerName='$t("Not Applicable")' :headerTitle='$t("Not Applicable")' hide />        
        <OColumn field="Checklist" width="250" editable bulkUpdate :headerName='$t("Checklist")' :headerTitle='$t("Checklist")' v-slot:editor="{modelValue: row}"  >
            <ChecklistLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{
                    row.Checklist_ID = sel.ID; 
                    row.Checklist = sel.Name; 
                    row.Revision = sel.Revision; 
                    row.Procedure = sel.Procedure; 
                    row.Discipline = sel.Discipline;
                    row.Discipline_ID = sel.Discipline_ID;
                    row.CompletionPhase = sel.CompletionPhase;
                    row.CompletionPhase_ID = sel.CompletionPhase_ID;
                    row.FrequencyNumberOfDays = sel.FrequencyNumberOfDays;
                }" textInput :textInputValue="row.Checklist" :ActivityGroupID="row.ActivityGroup_ID">
            </ChecklistLookup>
        </OColumn>
        <OColumn field="CheckItemsStatus" width="100" :headerName='$t("CheckItems Status")' class="text-center" :headerTitle='$t("CheckItems Status")'  />
        <OColumn field="CheckItemsStatusPercentage" sortable width="100" :headerName='$t("CheckItems Status %")' class="text-center" :headerTitle='$t("CheckItems Status %")' v-slot="{row}" hide>
            {{row.CheckItemsStatusPercentage}}<span v-if="row.CheckItemsStatusPercentage !== null">%</span>
        </OColumn>
        <OColumn field="OutstandingSignatures" width="100" :headerName='$t("Outst. Sign.")' class="text-end" :headerTitle='$t("Outstanding Signatures")' hide />
        <OColumn field="TotalSignatures" width="100" :headerName='$t("Tot. Sign.")' class="text-end" :headerTitle='$t("Total Signatures")' hide />
        <OColumn field="SignatureStatusPercentage" sortable width="100" :headerName='$t("Sign Status %")' class="text-center" :headerTitle='$t("Sign Status %")' v-slot="{row}" hide>
            {{row.SignatureStatusPercentage}}<span v-if="row.SignatureStatusPercentage !== null">%</span>
        </OColumn>        
        <OColumn field="Package" width="250" editable sortable bulkUpdate :headerName='$t("Package")' :headerTitle='$t("Package")' v-slot:editor="{modelValue: row}" hide >
            <PackageLookup 
                :OrgUnitId="row.OrgUnit_ID" :bind="sel=>{row.Package_ID = sel.ID; row.Package = sel.NameAndTitle;}" textInput :textInputValue="row.Package">
            </PackageLookup>
        </OColumn>
        <OColumn field="Shutdown" width="250" editable sortable bulkUpdate :headerName='$t("Shutdown")' :headerTitle='$t("Shutdown")' v-slot:editor="{modelValue: row}" hide >
            <ShutdownsLookup 
                :OrgUnitId="row.OrgUnit_ID" :bind="sel=>{row.Shutdown_ID = sel.ID; row.Shutdown = sel.NameAndTitle;}" textInput :textInputValue="row.Shutdown">
            </ShutdownsLookup>
        </OColumn>
        <OColumn field="MainArea" width="250" editable sortable bulkUpdate :headerName='$t("Main Area")' :headerTitle='$t("Main Area")' v-slot:editor="{modelValue: row}" >
            <MainAreasLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.MainArea_ID = sel.ID; row.MainArea = sel.NameAndTitle;}" textInput :textInputValue="row.MainArea">
            </MainAreasLookup>
        </OColumn>
        <OColumn field="Interface" width="250" editable sortable bulkUpdate :headerName='$t("Interface")' :headerTitle='$t("Interface")' v-slot:editor="{modelValue: row}" hide >
            <InterfaceLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.Interface_ID = sel.ID; row.Interface = sel.Name;}" textInput :textInputValue="row.Interface">
            </InterfaceLookup>
        </OColumn>        
        <OColumn field="RelatedActivitiesCount" width="100" :headerName='$t("Rel. Act.#")' class="text-center" :headerTitle='$t("Related Activities Count")'  />
        <OColumn field="OutstandingRelatedActivitiesCount" width="100" :headerName='$t("Outst. Rel. Act.#")' class="text-center" :headerTitle='$t("Outstanding Related Activities Count")' hide />
        <OColumn field="IsSpotChecked" editable width="100" :headerName='$t("Spot Checked")' class="text-center" :headerTitle='$t("Spot Checked")' hide />
        <OColumn field="SpotCheckedBy" width="130" :headerName='$t("Spot Checked By")' :headerTitle='$t("Spot Checked By")' hide />
        <OColumn field="SpotChecked" width="130" :headerName='$t("Spot Checked Date")' :headerTitle='$t("Spot Checked Date")' hide />
        <OColumn field="PlannedStart" width="130" :headerName='$t("Planned Start")' :headerTitle='$t("Planned Start")' editable sortable bulkUpdate hide />
        <OColumn field="PlannedFinish" width="130" :headerName='$t("Planned Finish")' :headerTitle='$t("Planned Finish")' editable sortable bulkUpdate hide />
        <OColumn field="ActualProgress" width="130" :headerName='$t("Act. Progress")' class="text-end" :headerTitle='$t("Actual Progress")' editable sortable bulkUpdate hide />
        <OColumn field="Cost" width="80" :headerName='$t("Cost")' class="text-end" :headerTitle='$t("Cost")'  sortable hide />
        <OColumn field="EarnedHours" width="110" :headerName='$t("Earned Hours")' class="text-end" :headerTitle='$t("Earned Hours")'  sortable hide />
        <OColumn field="EarnedValue" width="110" :headerName='$t("Earned Value")' class="text-end" :headerTitle='$t("Earned Value")'  sortable hide />
        <OColumn field="Forecast" width="130" :headerName='$t("Forecast")' :headerTitle='$t("Forecast")' editable sortable bulkUpdate hide />
        <OColumn field="Completed" width="120" :headerName='$t("Completed")' :headerTitle='$t("Completed")' :editable="row => !row.HaveItems" sortable hide />
        <OColumn field="CompletedBy" width="120" :headerName='$t("Completed By")' :headerTitle='$t("Completed By")' hide />
        <OColumn field="TargetObjects" width="300" :headerName='$t("Target Objects")' :headerTitle='$t("Target Objects")' v-slot="{row}" hide>
            <span v-for="(item, index) in JSON.parse(row.TargetObjectsJson)">
                <a :href="`/objects/objectdetails?ID=${item.ID}`" target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.Name }}</a>&nbsp; 
            </span>                
        </OColumn>
        <OColumn v-slot="{row}" width="110" :headerName='$t("Schedule")' field="Schedule_ID" :headerTitle='$t("Schedule")'>
            <a target="_blank" :href="`/${row.DefaultViewUrl}?ID=${row.ID}`">{{row.Schedule_ID}}</a>
        </OColumn>
        <OColumn field="Calendar" width="150" editable sortable bulkUpdate :headerName='$t("Calendar")' :headerTitle='$t("Calendar")' v-slot:editor="{modelValue: row}" hide >
            <CalendarLookup 
                :bind="sel=>{row.Calendar_ID = sel.ID; row.Calendar = sel.Name;}" textInput :textInputValue="row.Calendar">
            </CalendarLookup>
        </OColumn>
        <OColumn field="Duration" editable width="80" :headerName='$t("Duration")' class="text-end" :headerTitle='$t("Duration")' hide />
        <OColumn field="ConstraintNameAndType" width="140" :headerName='$t("Constraint")' :headerTitle='$t("Constraint")' hide />
        <OColumn field="ConstraintDate" width="120" :headerName='$t("Constraint Date")' :headerTitle='$t("Constraint Date")' format="Short Date" hide />
        <OColumn field="ZoneParentAndName" width="140" :headerName='$t("Zone/Train")' :headerTitle='$t("Zone/Train")' hide />
        <OColumn field="TaktWagon" width="140" :headerName='$t("Taktwagon")' :headerTitle='$t("Taktwagon")' hide />
        <OColumn field="Parent" width="250" editable sortable bulkUpdate :headerName='$t("Parent")' :headerTitle='$t("Parent")' v-slot:editor="{modelValue: row}" hide >
            <ActivitiesLookup 
                :bind="sel=>{row.Parent_ID = sel.ID; row.Parent = sel.Name;}" textInput :textInputValue="row.Parent">
            </ActivitiesLookup>
        </OColumn>
        <OColumn field="StatusComments" editable width="250" :headerName='$t("Status Comments")' :headerTitle='$t("Status Comments")' hide />
        
        <OColumn field="HOT1_ID" sortable width="150" :headerName='dsHOT1.current.Name' v-if="dsHOT1.current.Name" :headerTitle='dsHOT1.current.Name' v-slot="{row}" >
            <span v-for="(item, index) in JSON.parse(row.HOT1)">
                <a  :class="item.Closed ? 'text-decoration-line-through' : ''" 
                    :href="`/activity?ID=${item.ID}`" 
                    target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.ID }}</a>&nbsp; 
            </span> 
        </OColumn>
        <OColumn field="HOT2_ID" sortable width="150" :headerName='dsHOT2.current.Name' v-if="dsHOT2.current.Name" :headerTitle='dsHOT2.current.Name' v-slot="{row}" >
            <span v-for="(item, index) in JSON.parse(row.HOT2)">
                <a  :class="item.Closed ? 'text-decoration-line-through' : ''" 
                    :href="`/activity?ID=${item.ID}`" 
                    target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.ID }}</a>&nbsp; 
            </span> 
        </OColumn>
        <OColumn field="HOT3_ID" sortable width="150" :headerName='dsHOT3.current.Name' v-if="dsHOT3.current.Name" :headerTitle='dsHOT3.current.Name' v-slot="{row}" >
            <span v-for="(item, index) in JSON.parse(row.HOT3)">
                <a  :class="item.Closed ? 'text-decoration-line-through' : ''" 
                    :href="`/activity?ID=${item.ID}`" 
                    target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.ID }}</a>&nbsp; 
            </span> 
        </OColumn>
        <OColumn field="HOT4_ID" sortable width="150" :headerName='dsHOT4.current.Name' v-if="dsHOT4.current.Name" :headerTitle='dsHOT4.current.Name' v-slot="{row}" >
            <span v-for="(item, index) in JSON.parse(row.HOT4)">
                <a  :class="item.Closed ? 'text-decoration-line-through' : ''" 
                    :href="`/activity?ID=${item.ID}`" 
                    target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.ID }}</a>&nbsp; 
            </span> 
        </OColumn>
        <OColumn field="HOT5_ID" sortable width="150" :headerName='dsHOT5.current.Name' v-if="dsHOT5.current.Name" :headerTitle='dsHOT5.current.Name' v-slot="{row}" >
            <span v-for="(item, index) in JSON.parse(row.HOT5)">
                <a  :class="item.Closed ? 'text-decoration-line-through' : ''" 
                    :href="`/activity?ID=${item.ID}`" 
                    target="_blank" style="outline:none;white-space:nowrap;overflow-y: auto">{{ item.ID }}</a>&nbsp; 
            </span> 
        </OColumn>        
        <OColumn field="Systems" width="250" :headerName='$t("Systems")' :headerTitle='$t("Systems")' hide />
        <OColumn field="Created" width="120" :headerName='$t("Created")' :headerTitle='$t("Created")' hide />
        <OColumn field="CreatedBy" width="140" :headerName='$t("Created By")' :headerTitle='$t("Created By")' hide />
        <OColumn field="Updated" width="120" :headerName='$t("Updated")' :headerTitle='$t("Updated")' hide />
        <OColumn field="UpdatedBy" width="140" :headerName='$t("Updated By")' :headerTitle='$t("Updated By")' hide />
        <OColumn field="IShallSign" width="100" :headerName='$t("I shall sign")' :headerTitle='$t("I shall sign")' />
        <OColumn field="OpenCatAWorkflows" sortable width="120" class="text-end" :headerName='$t("Open Cat A Punch")' :headerTitle='$t("Open Cat A Punch")' />
        <OColumn field="OpenWorkflows" sortable width="120" class="text-end" :headerName='$t("Open WF")' :headerTitle='$t("Open WF")' />
        <OColumn field="Closed" sortable width="90" :headerName='$t("Closed")' :headerTitle='$t("Closed")' format="Short Date" hide />
        <OColumn field="ClosedBy" width="200" :headerName='$t("Closed By")' :headerTitle='$t("Closed By")' hide />
        <OColumn field="ConstructionPhase" width="250" editable sortable bulkUpdate :headerName='$t("Construction Phase")' :headerTitle='$t("Construction Phase")' v-slot:editor="{modelValue: row}" hide >
            <ConstructionPhasesLookup 
                :OrgUnitId="row.OrgUnit_ID" :bind="sel=>{row.ConstructionPhase_ID = sel.ID; row.ConstructionPhase = sel.Name;}" textInput :textInputValue="row.ConstructionPhase">
            </ConstructionPhasesLookup>
        </OColumn>        
        <OColumn field="ExternalRef" width="150" editable sortable bulkUpdate :headerName='$t("External Ref")' :headerTitle='$t("External Ref")' hide />
        <OColumn field="Frequency" width="140" sortable :headerName='$t("Repeat Frequency")' :headerTitle='$t("Repeat Frequency")' hide />
        <OColumn field="Milestone" width="250" editable sortable bulkUpdate :headerName='$t("Milestone")' :headerTitle='$t("Milestone")' v-slot:editor="{modelValue: row}" hide >
            <MilestonesLookup 
                :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.Milestone_ID = sel.ID; row.Milestone = sel.NameAndTitle; row.MilestoneDate = sel.MilestoneDate}" textInput :textInputValue="row.Milestone">
            </MilestonesLookup>
        </OColumn>  
        <OColumn field="MilestoneDate" width="140" sortable :headerName='$t("Milestone Date")' :headerTitle='$t("Milestone Date")' hide />
        <OColumn field="WBSName" width="250" editable sortable bulkUpdate :headerName='$t("WBS")' :headerTitle='$t("WBS")' v-slot:editor="{modelValue: row}" hide >
            <WBSLookup 
                :bind="sel=>{row.WBS_ID = sel.ID; row.WBSName = sel.Name; }" textInput :textInputValue="row.WBSName">
            </WBSLookup>
        </OColumn>
        <OColumn field="EstHours" width="120" editable sortable bulkUpdate class="text-end" :headerName='$t("Est Hours")' :headerTitle='$t("Est Hours")' hide />
        <OColumn field="ActHours" width="120" editable sortable bulkUpdate class="text-end" :headerName='$t("Act Hours")' :headerTitle='$t("Act Hours")' hide />
        <OColumn field="Text1" width="120" editable sortable bulkUpdate :headerName='$t("Text1")' :headerTitle='$t("Text1")' hide />
        <OColumn field="Text2" width="120" editable sortable bulkUpdate :headerName='$t("Text2")' :headerTitle='$t("Text2")' hide />
        <OColumn field="Deleted" width="120" editable sortable bulkUpdate :headerName='$t("Deleted")' :headerTitle='$t("Deleted")'  format="Short Date" hide />
        <OColumn field="DeletedReason" width="150" editable sortable bulkUpdate :headerName='$t("Deleted / Cancelled Reason")' :headerTitle='$t("Deleted Reason")' hide />
        <OColumn field="NoOfAttachments" width="120" :headerName='$t("No Of Attachments")' class="text-end" :headerTitle='$t("No Of Attachments")' hide  />
        <OColumn field="GroupSort1" width="150" editable sortable bulkUpdate :headerName='$t("Group Sort1")' :headerTitle='$t("Group Sort 1")' hide />
        <OColumn field="GroupSort2" width="150" editable sortable bulkUpdate :headerName='$t("Group Sort2")' :headerTitle='$t("Group Sort 2")' hide />
        <OColumn field="GroupSort3" width="150" editable sortable bulkUpdate :headerName='$t("Group Sort3")' :headerTitle='$t("Group Sort 3")' hide />
        <OColumn field="GroupSort4" width="150" editable sortable bulkUpdate :headerName='$t("Group Sort4")' :headerTitle='$t("Group Sort 4")' hide />
        <OColumn field="ActivityMeta1" v-slot:editor="{modelValue: row}" editable bulkUpdate width="150" sortable :headerName='$t("ActivityMeta1")' :headerTitle='$t("ActivityMeta1")' hide >
            <MetaObjectLookup :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ActivityMeta1 = sel.Name; }" MetaField="ActivityMeta1" textInput :textInputValue="row.ActivityMeta1" />
        </OColumn>
        <OColumn field="ActivityMeta2" v-slot:editor="{modelValue: row}" editable bulkUpdate width="150" sortable :headerName='$t("ActivityMeta2")' :headerTitle='$t("ActivityMeta2")' hide >
            <MetaObjectLookup :IdPath="row.OrgUnitIdPath" :bind="sel=>{row.ActivityMeta2 = sel.Name; }" MetaField="ActivityMeta2" textInput :textInputValue="row.ActivityMeta2" />
        </OColumn>
        <OColumn field="FollowUpItems" width="150" sortable :headerName='$t("Follow Up Items")' :headerTitle='$t("Follow Up Items")' hide />
        <OColumn field="LiveningUpNotice" width="55" sortable :headerName='$t("LUN")' :headerTitle='$t("Livening Up Notice")' hide />
        <OColumn field="LUNActivationDate" width="120" format="Short Date" editable bulkUpdate sortable :headerName='$t("LUN Date")' :headerTitle='$t("Livening Up Notice Activation Date")' hide />
        <OColumn field="LUNWithdrawalDate" width="120" format="Short Date" editable bulkUpdate sortable :headerName='$t("LUN Withdrawal")' :headerTitle='$t("Livening Up Notice Withdrawal Date")' hide />
        <OColumn field="RiskCount" width="90" sortable :headerName='$t("Risk Count")' :headerTitle='$t("Risk Count")' hide />
        <OColumn field="ProgressNotMeasured" width="90" editable sortable :headerName='$t("PNM")' :headerTitle='$t("Progress Not Measured")' hide />
        <OColumn field="PlannedProgress" width="90" sortable :headerName='$t("Planned Progress")' :headerTitle='$t("Planned Progress")' hide />
        <OColumn field="ActivityResponsible" editable bulkUpdate width="150" sortable :headerName='$t("Activity Responsible")' v-slot:editor="{modelValue: row}" :headerTitle='$t("Activity Responsible")' hide>             
            <PersonsLookup :bind="sel => { row.ActivityResponsible = sel.Name; row.ActivityResponsible_ID = sel.ID }">
                    <template #target="{ target }">
                        <input :ref="target" :value="row.ActivityResponsible" />
                    </template>
                </PersonsLookup>
        </OColumn>

    </ODataGrid>
</template>

<script setup>
    import { computed, ref, watch, defineEmits } from 'vue';
    import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import OColumn from "o365.vue.components.DataGrid.Column.jsx"
    import OOrgunitsLookup from "o365.vue.components.OrgUnitsLookup.vue";
    import ActivityTypesLookup from "complete.vue.components.ActivityTypesLookup.vue";
    import HandoverTypesLookup from "complete.vue.components.HandoverTypesLookup.vue";
    import DisciplinesLookup from "assets.vue.components.DisciplinesLookup.vue";
    import CompletionPhasesLookup from "complete.vue.components.CompletionPhasesLookup.vue";
    import ProjectPhasesLookup from "complete.vue.components.ProjectPhasesLookup.vue";
    import SubProjectsLookup from "complete.vue.components.SubProjectsLookup.vue";
    import ClassificationCodesLookup from "complete.vue.components.ClassificationCodesLookup.vue";
    import ChecklistLookup from "check.vue.components.ChecklistLookup.vue";
    import PackageLookup from "complete.vue.components.PackageLookup.vue";
    import ShutdownsLookup from "complete.vue.components.ShutdownsLookup.vue";
    import MainAreasLookup from "assets.vue.components.MainAreasLookup.vue";
    import InterfaceLookup from "complete.vue.components.InterfaceLookup.vue";
    import CalendarLookup from "system.vue.components.CalendarLookup.vue";
    import ActivitiesLookup from "complete.vue.components.ActivitiesLookup.vue";
    import ConstructionPhasesLookup from "complete.vue.components.ConstructionPhasesLookup.vue";
    import MilestonesLookup from "complete.vue.components.MilestonesLookup.vue";
    import WBSLookup from "cost.vue.components.WBSLookup.vue";
    import MetaObjectLookup from "assets.vue.components.MetaObjectLookup.vue";
    import PersonsLookup from "o365.vue.components.PersonsLookup.vue";

    const includeDeletedToggle = ref(false); 
    const includeClosedToggle = ref(false); 
    const CurrentActivityID = ref();
 
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsHOT1 = getOrCreateDataObject({
        id: 'dsHOTLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypes',
        maxRecords: 1,
        whereClause: "HOT1=1",
        fields:
             [{name: "Name", type: "string" }]
    }); 

    const dsHOT2 = getOrCreateDataObject({
        id: 'dsHOTLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypes',
        maxRecords: 1,
        whereClause: "HOT2=1",
        fields:
             [{name: "Name", type: "string" }]
    }); 

    const dsHOT3 = getOrCreateDataObject({
        id: 'dsHOTLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypes',
        maxRecords: 1,
        whereClause: "HOT3=1",
        fields:
             [{name: "Name", type: "string" }]
    }); 

    const dsHOT4 = getOrCreateDataObject({
        id: 'dsHOTLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypes',
        maxRecords: 1,
        whereClause: "HOT4=1",
        fields:
             [{name: "Name", type: "string" }]
    }); 

    const dsHOT5 = getOrCreateDataObject({
        id: 'dsHOTLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypes',
        maxRecords: 1,
        whereClause: "HOT5=1",
        fields:
             [{name: "Name", type: "string" }]
    }); 

    const dsActivities = getOrCreateDataObject({
        id: 'dsActivities' + crypto.randomUUID(),
        viewName: 'aviw_Complete_Activities',
        maxRecords: 25,
        whereClause: "",
        distinctRows: false,
        allowUpdate: false,
        allowInsert: false,
        allowDelete: false,
        fields: [
        {
            "name": "PrimKey"
        },
        {
            "name": "ID",
        },
        {
            "name": "Created",
            "sortOrder": 1,
            "sortDirection": "desc"
        },
        {
            "name": "CreatedBy_ID"
        },
        {
            "name": "Updated"
        },
        {
            "name": "UpdatedBy_ID"
        },
        {
            "name": "Name"
        },
        {
            "name": "ActivityType_ID"
        },
        {
            "name": "PlannedStart"
        },
        {
            "name": "PlannedFinish"
        },
        {
            "name": "CompletedBy_ID"
        },
        {
            "name": "Completed"
        },
        {
            "name": "Checklist_ID"
        },
        {
            "name": "Series"
        },
        {
            "name": "Closed"
        },
        {
            "name": "ClosedBy_ID"
        },
        {
            "name": "ActualProgress"
        },
        {
            "name": "Milestone_ID"
        },
        {
            "name": "EstHours"
        },
        {
            "name": "Parent_ID"
        },
        {
            "name": "WBS_ID"
        },
        {
            "name": "Text1"
        },
        {
            "name": "Text2"
        },
        {
            "name": "OrgUnit"
        },
        {
            "name": "Domain_ID"
        },
        {
            "name": "ActivityType"
        },
        {
            "name": "Checklist"
        },
        {
            "name": "CreatedBy"
        },
        {
            "name": "UpdatedBy"
        },
        {
            "name": "CompletedBy"
        },
        {
            "name": "Sequence"
        },
        {
            "name": "OrgUnitIdPath"
        },
        {
            "name": "MySignatureRequired"
        },
        {
            "name": "TypeOfView"
        },
        {
            "name": "Started"
        },
        {
            "name": "PredecessorsCount"
        },
        {
            "name": "Forecast"
        },
        {
            "name": "Procedure"
        },
        {
            "name": "HaveItems"
        },
        {
            "name": "IShallSign"
        },
        {
            "name": "Revision"
        },
        {
            "name": "ClosedWorkFlows"
        },
        {
            "name": "OrgUnit_ID"
        },
        {
            "name": "Calendar_ID"
        },
        {
            "name": "ConstraintType"
        },
        {
            "name": "ConstraintDate"
        },
        {
            "name": "ConstraintNameAndType"
        },
        {
            "name": "Duration"
        },
        {
            "name": "Calendar"
        },
        {
            "name": "Schedule_ID"
        },
        {
            "name": "ProgressPercentage"
        },
        {
            "name": "Milestone"
        },
        {
            "name": "Schedule"
        },
        {
            "name": "WBSName"
        },
        {
            "name": "ActHours"
        },
        {
            "name": "Predecessors"
        },
        {
            "name": "Parent"
        },
        {
            "name": "ExternalRef"
        },
        {
            "name": "AccessIdPath"
        },
        {
            "name": "MilestoneDate"
        },
        {
            "name": "TargetObjects"
        },
        {
            "name": "TargetObjectsJson"
        },
        {
            "name": "Deleted"
        },
        {
            "name": "DeletedReason"
        },
        {
            "name": "MainArea_ID"
        },
        {
            "name": "MainArea"
        },
        {
            "name": "Discipline_ID"
        },
        {
            "name": "Discipline"
        },
        {
            "name": "TotalWorkflows"
        },
        {
            "name": "OpenWorkflows"
        },
        {
            "name": "OpenCatAWorkflows"
        },
        {
            "name": "ActivityGroup"
        },
        {
            "name": "ActivityGroup_ID"
        },
        {
            "name": "ClosedBy"
        },
        {
            "name": "NoOfAttachments"
        },
        {
            "name": "ProjectPhase"
        },
        {
            "name": "ProjectPhase_ID"
        },
        {
            "name": "Package_ID"
        },
        {
            "name": "Package"
        },
        {
            "name": "Shutdown_ID"
        },
        {
            "name": "Shutdown"
        },
        {
            "name": "CheckItemsStatus"
        },
        {
            "name": "CheckItemsStatusPercentage"
        },
        {
            "name": "SubProject_ID"
        },
        {
            "name": "SubProject"
        },
        {
            "name": "HandoverType_ID"
        },
        {
            "name": "HandoverType"
        },
        {
            "name": "RelatedActivitiesCount"
        },
        {
            "name": "OutstandingRelatedActivitiesCount"
        },
        {
            "name": "CostProject_ID"
        },
        {
            "name": "ConstructionPhase"
        },
        {
            "name": "ConstructionPhase_ID"
        },
        {
            "name": "IsSpotChecked"
        },
        {
            "name": "SpotCheckedBy"
        },
        {
            "name": "SpotChecked"
        },
        {
            "name": "StatusComments"
        },
        {
            "name": "SpotcheckedBy_ID"
        },
        {
            "name": "HOT1"
        },
        {
            "name": "HOT2"
        },
        {
            "name": "HOT3"
        },
        {
            "name": "HOT4"
        },
        {
            "name": "HOT5"
        },
        {
            "name": "HOT1_ID"
        },
        {
            "name": "HOT2_ID"
        },
        {
            "name": "HOT3_ID"
        },
        {
            "name": "HOT4_ID"
        },
        {
            "name": "HOT5_ID"
        },
        {
            "name": "OutstandingSignatures"
        },
        {
            "name": "TotalSignatures"
        },
        {
            "name": "Cost"
        },
        {
            "name": "EarnedValue"
        },
        {
            "name": "EarnedHours"
        },
        {
            "name": "GroupSort1"
        },
        {
            "name": "GroupSort2"
        },
        {
            "name": "GroupSort3"
        },
        {
            "name": "Description"
        },
        {
            "name": "LUNActivationDate"
        },
        {
            "name": "LUNWithdrawalDate"
        },
        {
            "name": "LiveningUpNotice"
        },
        {
            "name": "GroupSort4"
        },
        {
            "name": "CompletionPhase_ID"
        },
        {
            "name": "CompletionPhase"
        },
        {
            "name": "Systems"
        },
        {
            "name": "SignatureStatus"
        },
        {
            "name": "SignatureStatusPercentage"
        },
        {
            "name": "Interface_ID"
        },
        {
            "name": "Interface"
        },
        {
            "name": "Responsibles"
        },
        {
            "name": "RiskCount"
        },
        {
            "name": "ActivityMeta1"
        },
        {
            "name": "ActivityMeta2"
        },
        {
            "name": "ZoneParentAndName"
        },
        {
            "name": "TaktWagon"
        },
        {
            "name": "FrequencyNumberOfDays"
        },
        {
            "name": "Frequency"
        },
        {
            "name": "ProgressNotMeasured"
        },
        {
            "name": "DefaultViewUrl"
        },
        {
            "name": "PlannedProgress"
        },
        {
            "name": "NotApplicable"
        },
        {
            "name": "UniqueID"
        },
        {
            "name": "ClassificationCode"
        },
        {
            "name": "FollowUpItems"
        },
        {
            "name": "ActivityResponsible"
        },
        {
            "name": "ActivityResponsible_ID"
        }
    ]
    }); 
    
    function searchContent(e) {
        if (e.which === 13) {
            dsActivities.dataSearch(e.srcElement.value, "afnc_Complete_Activities_Search");
        }        
    }

    var gGlobalFilter = "";
     
    function applyFilter(pFilter) {
        gGlobalFilter = pFilter;
        setWhereClause();
    }
    
    function setWhereClause() {
        var vFilters = [];
        if (!includeDeletedToggle.value){
            vFilters.push("Deleted IS NULL"); 
        }   
        if (!includeClosedToggle.value){
            vFilters.push("Closed IS NULL"); 
        }        
        if (gGlobalFilter !== "") {
            vFilters.push(gGlobalFilter);
        }
        dsActivities.recordSource.whereClause = vFilters.join(" AND ");            
        dsActivities.load()
    }
    
    watch([includeDeletedToggle, includeClosedToggle], setWhereClause, { immediate: true }); 

    useDataObjectEventListener(dsActivities, 'CurrentIndexChanged', (pOptions, pRow) => {
        if (dsActivities.current) emits('onactivitychanged', dsActivities.current.ID); 
    });
    useDataObjectEventListener(dsActivities, 'DataLoaded', (pOptions, pRow) => {
        if (dsActivities.getData().length>0) emits('onactivitychanged', dsActivities.getData()[0].ID); 
    });

    dsHOT1.load();
    dsHOT2.load();
    dsHOT3.load();
    dsHOT4.load();
    dsHOT5.load();

    dsActivities.enableContextFilter();
    dsActivities.recordSource.whereClause = "Deleted IS NULL AND Closed IS NULL";
     

    const emits = defineEmits( ["onactivitychanged"]);

    defineExpose({
        dsActivities, CurrentActivityID
    });

</script>